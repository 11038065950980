import React from "react";
import "./style.css";

function LoadingGenericComponent() {
  return (
    <div className="loading-wrapper">
      <div className="loading-hero loading-shimmer"></div>
      <div className="loading-items-wrapper">
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
        <div className="loading-item loading-shimmer"></div>
      </div>
    </div>
  );
}

export default LoadingGenericComponent;
