import React from "react";
import { useState } from "react";
import axios from "axios";
import "./style.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

function ContactComponent() {
  const name = useFormInput("");
  const email = useFormInput("");
  const subject = useFormInput("");
  const message = useFormInput("");

  const history = useHistory();

  const handleContactSubmit = (e: any) => {
    e.preventDefault();

    const contactInformation: any = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };

    axios
      .post("/api/mail/contact", contactInformation)
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title:
            "<span>Message sent successfully! I look forward to our conversation.</span>",
          showConfirmButton: false,
          // background: '#343a40',
          timer: 3000,
        });
      })
      .then(() => {
        setTimeout(() => {
          history.push("/");
        }, 3500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title:
            "<span>Oops! Something went wrong. Please try again later.</span>",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <main className="contactWrapper">
      <h1 className="contactHeading">Say Hello,</h1>
      <h3 className="contactSubHeading">and let's start a conversation.</h3>
      <form className="contactForm">
        {/* Name */}
        <h4 className="contactNameHeading">Name</h4>
        <input {...name} className="contactNameInput" />

        {/* Email */}
        <h4 className="contactEmailHeading">Email</h4>
        <input {...email} className="contactEmailInput" />

        {/* Subject */}
        <h4 className="contactSubjectHeading">Subject</h4>
        <input {...subject} className="contactSubjectInput" />

        {/* Message */}
        <h4 className="contactMessageHeading">Message</h4>
        <textarea {...message} className="contactMessageInput" />

        <button
          className="contactSubmitBtn"
          onClick={(e: any) => handleContactSubmit(e)}
        >
          Send
        </button>
      </form>
    </main>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    // console.log(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default ContactComponent;
