import React from "react";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";

function PrintsGalleryComponent() {
  const [galleryImages, setGalleryImages] = useState<any>([]);

  useEffect(() => {
    axios.get("/api/prints").then((res) => {
      // console.log(res.data);
      setGalleryImages(res.data);
    });
  }, []);

  const history = useHistory();

  return (
    <section className="galleryWrapper">
      <h1 className="galleryHeading">Art Prints</h1>
      <h3 className="galleryDesc">
        Prints of original artwork by Rebecca Stone, the creator of Enchanted
        Quill Artwork.{" "}
      </h3>
      <div className="galleryContentWrapper">
        {/* eventually will map from gallery table in database */}
        {galleryImages.map((el: any) => {
          return (
            <div key={el.id} className="galleryItemWrapper">
              <div
                className="galleryImage"
                style={{ backgroundImage: "url(" + el.location + ")" }}
                onClick={() => history.push("image/" + el.id)}
              ></div>
              <h3
                className="galleryArtName"
                onClick={() => history.push("image/" + el.id)}
              >
                {el.name}
              </h3>
              <h3 className="galleryArtPrice">From ${el.smallPrice}</h3>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default PrintsGalleryComponent;
