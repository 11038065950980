import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SingleStickersPinsComponent from '../components/SingleStickersPinsComponent'

function SingleStickersPins() {
  const [product, setProduct] = useState<any>()

  useEffect(() => {
    window.scrollTo(0, 0)

    const path = window.location.pathname
    const pathArr = path.split('/')
    const id = pathArr.pop()
    axios.get('/api/merch/id/' + id).then((res) => {
      setProduct(res.data)
    })
  }, [])

  return <SingleStickersPinsComponent {...product} />
}

export default SingleStickersPins
