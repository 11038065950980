import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SingleClothingComponent from '../components/SingleClothingComponent'

function SingleClothing() {
  const [product, setProduct] = useState<any>()

  useEffect(() => {
    window.scrollTo(0, 0)

    const path = window.location.pathname
    const pathArr = path.split('/')
    const id = pathArr.pop()
    axios.get('/api/clothing/id/' + id).then((res) => {
      setProduct(res.data)
    })
  }, [])

  return <SingleClothingComponent {...product} />
}

export default SingleClothing
