export const BREAKPOINTS = {}

export const COLORS = {
  darkBlue: '#002c50',
  medBlue: '#00417e',
  orange: '#e85a22',
  yellow: '#f2cd3d',
  offWhite: '#e6e9e0'
}

export const FONTS = {
  serif: 'Averia Serif Libre, cursive',
  sansSerif: 'Roboto, sans-serif'
}

export const FONT_WEIGHTS = {
  light: 300,
  mediumLight: 400,
  medium: 500,
  heavy: 700
}

