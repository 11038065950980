import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { getUser } from "../../utils/Session";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "./style.css";

function SingleImage(props: any) {
  const [userId, setUserId] = useState<number>();
  const [cartId, setCartId] = useState<number>();
  const [cart, setCart] = useState<any>();

  // display text for size dropdown button
  const [sizeDropdown, setSizeDropdown] = useState<string>("Select size");
  const [display, setDisplay] = useState<string>("none");

  const [printPrice, setPrintPrice] = useState<number>(props.smallPrice);
  // this price is what will be used for creating CartPrint
  const [individualPrintPrice, setIndividualPrintPrice] = useState<number>();
  const [quantity, setQuantity] = useState<number>(1);

  // this state will be used for tracking which image is blown up for the user
  const [isLargeImage, setIsLargeImage] = useState<string>(props.location);

  const [variantId, setVariantId] = useState<string>("");

  const history = useHistory();

  useEffect(() => {
    loadCart();
  }, []);

  const loadCart = async () => {
    if (getUser()) {
      setUserId(getUser().id);
      // get most recent cart associated with user
      await axios.get("/api/cart/id/" + getUser().id).then((res) => {
        setCart(res.data.mostRecentCart);
        // set cartId if cart already exists
        if (res.data.mostRecentCart.length)
          setCartId(res.data.mostRecentCart[0].id);
      });
    }
  };

  const handleAddToCart = () => {
    // send user to sign up page if not logged in
    if (getUser() === null) {
      history.push("/signup");
    } else {
      // if mostRecentCart empty, create new cart
      if (!cart.length) {
        createNewCart();
      } else {
        checkIfItemAlreadyInCart();
      }
    }
  };

  const createNewCart = async () => {
    await axios({
      method: "post",
      url: "/api/cart/create",
      headers: {},
      data: {
        user_id: userId,
      },
    }).then((res) => {
      setCartId(res.data.cart.id);
      const newCartId = parseInt(res.data.cart.id);
      setCart(res.data);

      axios({
        method: "post",
        url: "/api/cart-prints/create",
        headers: {},
        data: {
          name: props.name,
          price: individualPrintPrice,
          size: sizeDropdown,
          variantId: variantId,
          description: props.description,
          location: props.location,
          quantity: quantity,
          cart_id: newCartId,
        },
      })
        .then((res) => {
          Swal.fire({
            icon: "success",
            iconColor: "#41F2A0",
            title: "<span>Item added to cart!</span>",
            confirmButtonText: "Go to cart",
            confirmButtonColor: "#002c50",
            showCancelButton: true,
            cancelButtonText: "Continue shopping",
            cancelButtonColor: "#002c50",
          }).then((res) => {
            if (res.isConfirmed) {
              history.push("/cart");
            } else {
              history.goBack();
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const checkIfItemAlreadyInCart = async () => {
    await axios.get("/api/cart-prints/id/" + cartId).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        if (
          res.data[i].name === props.name &&
          res.data[i].size === sizeDropdown
        ) {
          Swal.fire({
            icon: "warning",
            title: "<span>Item already in cart!</span>",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
      }
      addItemToCart();
    });
  };

  const addItemToCart = async () => {
    await axios({
      method: "post",
      url: "/api/cart-prints/create",
      headers: {},
      data: {
        name: props.name,
        price: individualPrintPrice,
        size: sizeDropdown,
        variantId: variantId,
        description: props.description,
        location: props.location,
        quantity: quantity,
        cart_id: cartId,
      },
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title: "<span>Item added to cart!</span>",
          confirmButtonText: "Go to cart",
          confirmButtonColor: "#002c50",
          showCancelButton: true,
          cancelButtonText: "Continue shopping",
          cancelButtonColor: "#002c50",
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/cart");
          } else {
            history.goBack();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectSizeWarning = () => {
    Swal.fire({
      icon: "warning",
      title: "<span>Please select print size.</span>",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleQuantityIncrease = (e: any) => {
    if (sizeDropdown === "Select size") {
      selectSizeWarning();
    } else {
      setQuantity(() => quantity + 1);

      switch (sizeDropdown) {
        case '10 x 10"':
        case '12 x 18"':
          setPrintPrice(() => (quantity + 1) * props.smallPrice);
          break;
        case '14 x 14"':
        case '24 x 36"':
          setPrintPrice(() => (quantity + 1) * props.mediumPrice);
          break;
        case '18 x 18"':
          setPrintPrice(() => (quantity + 1) * props.largePrice);
          break;
        default:
          break;
      }
    }
  };

  const handleQuantityDecrease = () => {
    if (quantity !== 1) {
      setQuantity(() => quantity - 1);

      switch (sizeDropdown) {
        case '10 x 10"':
        case '12 x 18"':
          setPrintPrice(() => (quantity - 1) * props.smallPrice);
          break;
        case '14 x 14"':
        case '24 x 36"':
          setPrintPrice(() => (quantity - 1) * props.mediumPrice);
          break;
        case '18 x 18"':
          setPrintPrice(() => (quantity - 1) * props.largePrice);
          break;
        default:
          break;
      }
    }
  };

  const handleSelectSmallSize = () => {
    // set text for sizeDropdown based on dimensions of print
    if (props.dimensions === "square") setSizeDropdown('10 x 10"');
    else if (props.dimensions === "portrait") setSizeDropdown('12 x 18"');

    setPrintPrice(() => props.smallPrice * quantity);

    // hides dropdown content
    setDisplay("none");

    // sets price to be used to create CartPrint
    setIndividualPrintPrice(props.smallPrice);

    // sets variantId
    setVariantId(props.smallVariantId);
  };

  const handleSelectMediumSize = () => {
    // set text for sizeDropdown based on dimensions of print
    if (props.dimensions === "square") setSizeDropdown('14 x 14"');
    else if (props.dimensions === "portrait") setSizeDropdown('24 x 36"');

    setPrintPrice(() => props.mediumPrice * quantity);

    // hides dropdown content
    setDisplay("none");

    // sets price to be used to create CartPrint
    setIndividualPrintPrice(props.mediumPrice);

    // sets variantId
    setVariantId(props.mediumVariantId);
  };

  const handleSelectLargeSize = () => {
    // set text for sizeDropdown based on dimensions of print
    if (props.dimensions === "square") setSizeDropdown('18 x 18"');

    setPrintPrice(() => props.largePrice * quantity);

    // hides dropdown content
    setDisplay("none");

    // sets price to be used to create CartPrint
    setIndividualPrintPrice(props.largePrice);

    // sets variantId
    setVariantId(props.largeVariantId);
  };

  // adds '.00' if price is whole integer or '0' if price has only 1 decimal (e.g. 15 becomes $15.00 and 12.5 becomes $12.50)
  const formatPrice = () => {
    const countDecimals = (price: number) => {
      if (Math.floor(price) === price) return 0;
      return price.toString().split(".")[1].length || 0;
    };

    if (printPrice && countDecimals(printPrice) === 0) {
      return "$" + printPrice + ".00";
    } else if (printPrice && countDecimals(printPrice) === 1) {
      return "$" + printPrice + "0";
    } else if (printPrice) {
      return "$" + printPrice;
      // this is returned when component renders
    } else {
      return "From $" + props.smallPrice;
    }
  };

  return (
    <div className="singleImageComponentWrapper">
      <div className="printDetailsFlexContainer">
        {/* This section contains the art print images that users can toggle between */}
        <section className="printImages">
          <div
            className="singleImage"
            onClick={() => {
              isLargeImage
                ? window.open(
                    isLargeImage,
                    "Image",
                    "width=500, height=500, resizable=1"
                  )
                : window.open(
                    props.location,
                    "Image",
                    "width=500, height=500, resizable=1"
                  );
            }}
            style={
              isLargeImage
                ? { backgroundImage: "url(" + isLargeImage + ")" }
                : { backgroundImage: "url(" + props.location + ")" }
            }
          ></div>
          <div className="printAndCandidImageWrapper">
            <div
              className="smallSingleImage"
              onClick={() => setIsLargeImage(props.location)}
              style={{ backgroundImage: "url(" + props.location + ")" }}
            ></div>
            <div
              className="smallCandidImage"
              onClick={() => setIsLargeImage(props.candidLocation)}
              style={{ backgroundImage: "url(" + props.candidLocation + ")" }}
            ></div>
          </div>
        </section>
        {/* This section contains art print title, price, size selection, and quantity selection */}
        <section className="printLogistics">
          <h1 className="singleImageName">{props.name}</h1>
          <h3 className="singleImagePrice">{formatPrice()}</h3>
          <div className="isCenteredDropdown">
            <div
              className="sizeDropdown"
              onMouseOver={() => setDisplay("block")}
              onMouseOut={() => setDisplay("none")}
            >
              <button className="dropbtn">{sizeDropdown}</button>
              <div className="dropdownContent" style={{ display: display }}>
                <ul className="priceList">
                  <li onClick={() => handleSelectSmallSize()}>
                    {props.dimensions === "square" ? '10 x 10"' : '12 x 18"'}
                  </li>
                  <li onClick={() => handleSelectMediumSize()}>
                    {props.dimensions === "square" ? '14 x 14"' : '24 x 36"'}
                  </li>
                  {props.dimensions === "square" ? (
                    <li onClick={() => handleSelectLargeSize()}>18 x 18</li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="printQuantityCounter">
            <button
              className="decreasePrintQuantityBtn"
              onClick={() => handleQuantityDecrease()}
            >
              -
            </button>
            <input
              className="printQuantityInput"
              type="number"
              value={quantity}
              readOnly
            ></input>
            <button
              className="increasePrintQuantityBtn"
              onClick={(e: any) => handleQuantityIncrease(e)}
            >
              +
            </button>
          </div>
          <div
            className="addToCartBtn"
            onClick={() => {
              sizeDropdown === "Select size"
                ? selectSizeWarning()
                : handleAddToCart();
            }}
          >
            <p className="addToCartText">Add to Cart</p>
          </div>
        </section>
      </div>
      <div className="singleImageDescWrapper">
        <p>About this item:</p>
        <ul className="singleImageDescList">
          <li>
            Luster finish provides an elegant surface that resists fingerprints
            and virtually eliminates glare
          </li>
          <li>Paper weight 69lbs</li>
          <li>
            Exceptional image quality and longevity; printed with lifelong inks
            that protect the artwork from degredation caused by gas and light
          </li>
          <li>Drawn with love</li>
        </ul>
      </div>
      {/* <div
        className="addToCartBtn"
        onClick={() => {
          sizeDropdown === "Select size"
            ? selectSizeWarning()
            : handleAddToCart();
        }}
      >
        <p className="addToCartText">Add to Cart</p>
      </div> */}
      <div
        className="returnToHomeBtn"
        onClick={() => {
          history.goBack();
        }}
      >
        <p className="returnToHomeTxt">Return to Previous</p>
      </div>
    </div>
  );
}

export default SingleImage;
