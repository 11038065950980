import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getUser } from "../../utils/Session";
import Swal from "sweetalert2";
import "./style.css";

function CartComponent() {
  const [cartId, setCartId] = useState<number>();
  const [cartPrints, setCartPrints] = useState<any>([]);
  const [cartClothing, setCartClothing] = useState<any>([]);
  const [cartMerch, setCartMerch] = useState<any>([]);
  const [priceTotal, setPriceTotal] = useState<number>();

  const history = useHistory();

  const userId = getUser().id;

  // set cartId once component is rendered
  useEffect(() => {
    // get most recent cart associated with user
    axios.get("/api/cart/id/" + userId).then((res) => {
      // set cart id if cart exists
      if (res.data.mostRecentCart.length)
        setCartId(res.data.mostRecentCart[0].id);
    });
  }, []);

  // get cart contents once component is rendered
  useEffect(() => {
    axiosRequests();
  }, [cartId]);

  // axios requests for getting products in cart
  const axiosRequests = async () => {
    // get CartPrints associated with cartId
    let temporaryPriceTotal: number;
    await axios
      .get("/api/cart-prints/id/" + cartId)
      .then((res) => {
        setCartPrints(res.data);

        // let temporaryPriceTotal: number;
        res.data.forEach((el: any) => {
          let itemPrice: number = parseFloat(el.price);

          if (temporaryPriceTotal !== undefined) {
            temporaryPriceTotal = temporaryPriceTotal + itemPrice * el.quantity;
            setPriceTotal(temporaryPriceTotal);
          } else {
            temporaryPriceTotal = itemPrice * el.quantity;
            setPriceTotal(temporaryPriceTotal);
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });

    // get CartClothing associated with cartId
    await axios
      .get("/api/cart-clothing/id/" + cartId)
      .then((res) => {
        setCartClothing(res.data);

        // let temporaryPriceTotal: number;
        res.data.forEach((el: any) => {
          let itemPrice: number = parseFloat(el.price);

          if (temporaryPriceTotal !== undefined) {
            temporaryPriceTotal = temporaryPriceTotal + itemPrice;
            if (priceTotal) {
              setPriceTotal(temporaryPriceTotal + priceTotal);
            } else {
              setPriceTotal(temporaryPriceTotal);
            }
          } else {
            temporaryPriceTotal = itemPrice;
            setPriceTotal(temporaryPriceTotal);
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });

    // get CartMerchItems associated with cartId
    await axios
      .get("/api/cart-merch/id/" + cartId)
      .then((res) => {
        setCartMerch(res.data);

        // let temporaryPriceTotal: number;
        res.data.forEach((el: any) => {
          let itemPrice: number = parseFloat(el.price);

          if (temporaryPriceTotal !== undefined) {
            temporaryPriceTotal = temporaryPriceTotal + itemPrice * el.quantity;
            setPriceTotal(temporaryPriceTotal);
          } else {
            temporaryPriceTotal = itemPrice * el.quantity;
            setPriceTotal(temporaryPriceTotal);
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleQuantityIncrease = async (e: any) => {
    let key = e.target.dataset.key;
    let itemType = e.target.dataset.type;

    // quantity on increase btn click
    let quantity = parseInt(e.target.parentNode.firstChild.nextSibling.value);

    // used to increase quantity by one
    let quantityIncrease;

    // update increase of quantity of item on server based on type
    if (itemType === "Prints") {
      quantityIncrease = quantity + 1;

      axios({
        method: "put",
        url: "/api/cart-prints/update-quantity/id/" + key,
        headers: {},
        data: {
          id: key,
          quantity: quantityIncrease,
        },
      })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (itemType === "Stickers & Pins") {
      const merchId: number = e.target.dataset.merch;

      let merchItemQuantity: number | undefined;
      await axios({
        method: "get",
        url: `/api/merch/id/${merchId}`,
        headers: {},
      })
        .then((res) => {
          merchItemQuantity = res.data.quantity;
          return merchItemQuantity;
        })
        .catch((err) => console.error(err));

      // don't let users buy more than what's in stock
      if (merchItemQuantity && quantity < merchItemQuantity) {
        quantityIncrease = quantity + 1;

        await axios({
          method: "put",
          url: "/api/cart-merch/update-quantity/id/" + key,
          headers: {},
          data: {
            id: key,
            quantity: quantityIncrease,
          },
        })
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        Swal.fire({
          title: "<span>You've got the last one!</span>",
          confirmButtonText: "Ok",
          confirmButtonColor: "#002c50",
        });
      }
    }
  };

  const handleQuantityDecrease = (e: any) => {
    let key = e.target.dataset.key;
    let itemType = e.target.dataset.type;
    let quantity = parseInt(e.target.parentNode.firstChild.nextSibling.value);
    let quantityDecrease = quantity - 1;

    // update decrease of quantity of item on server based on type
    if (quantity !== 1 && itemType === "Prints") {
      axios({
        method: "put",
        url: "/api/cart-prints/update-quantity/id/" + key,
        headers: {},
        data: {
          id: key,
          quantity: quantityDecrease,
        },
      })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (quantity !== 1 && itemType === "Stickers & Pins") {
      axios({
        method: "put",
        url: "/api/cart-merch/update-quantity/id/" + key,
        headers: {},
        data: {
          id: key,
          quantity: quantityDecrease,
        },
      })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      Swal.fire({
        title: "<span>Remove item from cart?</span>",
        confirmButtonText: "Yes",
        confirmButtonColor: "#002c50",
        showCancelButton: true,
        cancelButtonColor: "#f15a22",
        // confirmButtonColor: 'black'
        // background: '#343a40'
      }).then((res) => {
        // remove item from cart on server based on type
        if (res.isConfirmed && itemType === "Prints") {
          axios({
            method: "delete",
            url: "/api/cart-prints/delete/id/" + key,
            headers: {},
            data: {
              id: key,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  icon: "success",
                  iconColor: "#41F2A0",
                  title: "<span>Item removed from cart.</span>",
                  showConfirmButton: false,
                  // background: '#343a40',
                  timer: 1500,
                });
              }
            })
            .then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 1750);
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (res.isConfirmed && itemType === "Stickers & Pins") {
          axios({
            method: "delete",
            url: "/api/cart-merch/delete/id/" + key,
            headers: {},
            data: {
              id: key,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  icon: "success",
                  iconColor: "#41F2A0",
                  title: "<span>Item removed from cart.</span>",
                  showConfirmButton: false,
                  // background: '#343a40',
                  timer: 1500,
                });
              }
            })
            .then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 1750);
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (res.isDenied) {
          return;
        }
      });
    }
  };

  const handleRemovePrintFromCart = (e: any) => {
    const key = e.target.parentNode.parentNode.dataset.key;

    Swal.fire({
      title: "<span>Remove item from cart?</span>",
      confirmButtonText: "Yes",
      confirmButtonColor: "#002c50",
      showCancelButton: true,
      cancelButtonColor: "#f15a22",
      // background: '#343a40'
    }).then((res) => {
      if (res.isConfirmed) {
        axios({
          method: "delete",
          url: "/api/cart-prints/delete/id/" + key,
          headers: {},
          data: {
            id: key,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                iconColor: "#41F2A0",
                title: "<span>Item removed from cart.</span>",
                showConfirmButton: false,
                // background: '#343a40',
                timer: 1500,
              });
            }
          })
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1750);
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (res.isDenied) {
        return;
      }
    });
  };

  const handleRemoveClothingFromCart = (e: any) => {
    const key = e.target.parentNode.parentNode.dataset.key;

    Swal.fire({
      title: "<span>Remove item from cart?</span>",
      confirmButtonText: "Yes",
      confirmButtonColor: "#002c50",
      showCancelButton: true,
      cancelButtonColor: "#f15a22",
    }).then((res) => {
      if (res.isConfirmed) {
        axios({
          method: "delete",
          url: "/api/cart-clothing/delete/id/" + key,
          headers: {},
          data: {
            id: key,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                iconColor: "#41F2A0",
                title: "<span>Item removed from cart.</span>",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1750);
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (res.isDenied) {
        return;
      }
    });
  };

  const handleRemoveMerchFromCart = (e: any) => {
    const key = e.target.parentNode.parentNode.dataset.key;

    Swal.fire({
      title: "<span>Remove item from cart?</span>",
      confirmButtonText: "Yes",
      confirmButtonColor: "#002c50",
      showCancelButton: true,
      cancelButtonColor: "#f15a22",
      // background: '#343a40'
    }).then((res) => {
      if (res.isConfirmed) {
        axios({
          method: "delete",
          url: "/api/cart-merch/delete/id/" + key,
          headers: {},
          data: {
            id: key,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                iconColor: "#41F2A0",
                title: "<span>Item removed from cart.</span>",
                showConfirmButton: false,
                // background: '#343a40',
                timer: 1500,
              });
            }
          })
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1750);
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (res.isDenied) {
        return;
      }
    });
  };

  const formatPrice = () => {
    const countDecimals = (price: number) => {
      if (Math.floor(price) === price) return 0;
      return price.toString().split(".")[1].length || 0;
    };

    if (priceTotal && countDecimals(priceTotal) === 0) {
      return "$" + priceTotal + ".00";
    } else if (priceTotal && countDecimals(priceTotal) === 1) {
      return "$" + priceTotal + "0";
    } else if (priceTotal) {
      return "$" + priceTotal;
    }
  };

  // check to see that items in cart are still available before checking out
  const checkItemAvailability = (): string => {
    // ! currently doesn't work because there is no 'printId' in cartPrints
    // if (cartPrints.length) {
    //   cartPrints.forEach(async (item: any) => {
    //     console.log(item);
    //     await axios
    //       .get(`/api/prints/id/${item.printId}`)
    //       .then((res: any) => {
    //         if (!res.data) {
    //           handleItemUnavailable(item.id, item.name, "print");
    //           return;
    //         }
    //       })
    //       .catch((err: any) => console.log(err));
    //   });
    // }

    if (cartClothing.length) {
      cartClothing.forEach(async (item: any) => {
        await axios
          .get(`/api/clothing/id/${item.clothingId}`)
          .then((res: any) => {
            if (!res.data) {
              handleItemUnavailable(item.id, item.printName, "clothing");
              return;
            }
          })
          .catch((err: any) => console.error(err));
      });
    }

    if (cartMerch.length) {
      cartMerch.forEach(async (item: any) => {
        await axios
          .get(`/api/merch/id/${item.merchId}`)
          .then((res: any) => {
            if (!res.data) {
              handleItemUnavailable(item.id, item.printName, "merch");
              return;
            }
          })
          .catch((err: any) => console.error(err));

        await axios({
          method: "get",
          url: `/api/merch/id/${item.merchId}`,
          headers: {},
        })
          .then((res: any) => {
            if (item.quantity > res?.data?.quantity) {
              Swal.fire({
                title: `<span>Ope! ${item.name} is low in stock. We only have ${res?.data?.quantity} left.</span>`,
                confirmButtonText: "Ok",
                confirmButtonColor: "#002c50",
              }).then(async () => {
                await axios({
                  method: "put",
                  url: `/api/cart-merch/update-quantity/id/${item.id}`,
                  headers: {},
                  data: {
                    id: item.id,
                    quantity: res?.data?.quantity,
                  },
                }).then(() => {
                  window.location.reload();
                });
              });
              return;
            }
          })
          .catch((err: any) => console.error(err));
      });
    }

    cartPrints.length &&
      sessionStorage.setItem("prints", JSON.stringify(cartPrints));

    return `/api/cart/create-checkout-session/id/${userId}`;
  };

  const handleItemUnavailable = (
    itemId: number,
    itemName: string,
    itemType: string
  ): void => {
    Swal.fire({
      title: `<span>Ope! ${itemName} just got snatched and is now out of stock.\n\nYou can check out other options, or keep an eye on social media to see what's coming up.</span>`,
      confirmButtonText: "Ok",
      confirmButtonColor: "#002c50",
    }).then(() => {
      switch (itemType) {
        case "print":
          axios
            .delete(`/api/cart-prints/delete/id/${itemId}`)
            .catch((err: any) => console.error(err));
          break;

        case "clothing":
          axios
            .delete(`/api/cart-clothing/delete/id/${itemId}`)
            .catch((err: any) => console.error(err));
          break;

        case "merch":
          axios
            .delete(`/api/cart-merch/delete/id/${itemId}`)
            .catch((err: any) => console.error(err));
          break;

        default:
          break;
      }
      window.location.reload();
    });
  };

  return (
    <>
      {cartPrints.length || cartClothing.length || cartMerch.length ? (
        <main className="cartMainWrapper">
          <h1 className="yourCartHeading">Your Cart</h1>
          <h3 className="cart-heading-message">
            My products are made-to-order, which is why they may take a bit
            longer to reach you. Making products on demand rather then ordering
            in bulk is more sustainable, and this is a one-woman show. Thank you
            for your support of the arts and my small business.
          </h3>
          <section className="cartWrapper">
            <form action={checkItemAvailability()} method="POST">
              <button className="proceedToCheckoutBtn" type="submit">
                Proceed to Checkout
              </button>
            </form>
            <h3 className="priceTotal">Subtotal: {formatPrice()}</h3>
            <div className="cartItemsWrapper">
              {/* Return all prints in cart */}
              {cartPrints.map((el: any) => {
                return (
                  <div key={el.id} data-key={el.id} className="cartItem">
                    <div className="cartItemRow1">
                      <div
                        className="cartItemImage"
                        style={{ backgroundImage: "url(" + el.location + ")" }}
                      ></div>
                      <div className="cartItemInfo">
                        <p className="cartItemName">{el.name}</p>
                        <p className="cartItemPrice">
                          {"$" + (el.price * el.quantity).toFixed(2)}
                        </p>
                        <p className="printSize">{el.size}</p>
                      </div>
                    </div>
                    <div className="cartItemRow2">
                      <div className="quantityCounter">
                        <button
                          className="decreaseQuantityBtn"
                          data-key={el.id}
                          data-type={"Prints"}
                          onClick={(e: any) => handleQuantityDecrease(e)}
                        >
                          -
                        </button>
                        <input
                          className="quantityInput"
                          type="number"
                          value={el.quantity}
                          readOnly
                        ></input>
                        <button
                          className="increaseQuantityBtn"
                          data-key={el.id}
                          data-type={"Prints"}
                          onClick={(e: any) => handleQuantityIncrease(e)}
                        >
                          +
                        </button>
                      </div>
                      <button
                        className="deleteItemBtn"
                        onClick={(e: any) => handleRemovePrintFromCart(e)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
              {/* return all clothing in cart */}
              {cartClothing.map((el: any) => {
                return (
                  <div key={el.id} data-key={el.id} className="cartItem">
                    <div className="cartItemRow1">
                      <div
                        className="cartItemImage"
                        style={{ backgroundImage: "url(" + el.location + ")" }}
                      ></div>
                      <div className="cartItemInfo">
                        <p className="cartItemName cartClothingPrintName">
                          {el.printName},{" "}
                          <span className="cartClothingDescName">
                            {el.descName}
                          </span>
                        </p>
                        <p className="cartItemPrice">
                          {el.price % 1 === 0
                            ? "$" + el.price * 1 + ".00"
                            : el.price}
                        </p>
                      </div>
                    </div>
                    <div className="clothingItemRow2">
                      <button
                        className="deleteItemBtn deleteClothingBtn"
                        onClick={(e: any) => handleRemoveClothingFromCart(e)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
              {/* return all stickers & pins in cart */}
              {cartMerch.map((el: any) => {
                return (
                  <div key={el.id} data-key={el.id} className="cartItem">
                    <div className="cartItemRow1">
                      <div
                        className="cartItemImage"
                        style={{ backgroundImage: "url(" + el.location + ")" }}
                      ></div>
                      <div className="cartItemInfo">
                        <p className="cartItemName cartClothingPrintName">
                          {el.printName},{" "}
                          <span className="cartClothingDescName">
                            {el.descName}
                          </span>
                        </p>
                        {/* if price * quantity is whole integer, add '.00' at the end; else simply price * quantity */}
                        <p className="cartItemPrice">
                          {(el.price * el.quantity) % 1 === 0
                            ? "$" + el.price * el.quantity + ".00"
                            : "$" + el.price * el.quantity}
                        </p>
                      </div>
                    </div>
                    <div className="cartItemRow2">
                      <div className="quantityCounter">
                        <button
                          className="decreaseQuantityBtn"
                          data-key={el.id}
                          data-type={"Stickers & Pins"}
                          onClick={(e: any) => handleQuantityDecrease(e)}
                        >
                          -
                        </button>
                        <input
                          className="quantityInput"
                          type="number"
                          value={el.quantity}
                          readOnly
                        ></input>
                        <button
                          className="increaseQuantityBtn"
                          data-key={el.id}
                          data-type={"Stickers & Pins"}
                          data-merch={el.merchId}
                          onClick={(e: any) => handleQuantityIncrease(e)}
                        >
                          +
                        </button>
                      </div>
                      <button
                        className="deleteItemBtn"
                        onClick={(e: any) => handleRemoveMerchFromCart(e)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </main>
      ) : (
        <main className="cartMainWrapper">
          <h1 className="yourCartHeading">Your cart is empty!</h1>
          <button
            className="proceedToCheckoutBtn"
            onClick={() => history.push("/")}
          >
            Go find something nice
          </button>
        </main>
      )}
    </>
  );
}

export default CartComponent;
