import React from "react";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";

function StickersPinsGalleryComponent() {
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vacationMode, setVacationMode] = useState<{
    vacationModeOn: boolean;
    message?: "string";
  }>({ vacationModeOn: false });

  useEffect(() => {
    // get existing merch items
    axios.get("/api/merch").then((res) => {
      // console.log(res.data);
      setGalleryImages(res.data);
    });

    // check whether vacation mode is active
    axios
      .get("/api/vacation-mode", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        setVacationMode({
          vacationModeOn: res.data.vacationModeOn,
          message: res.data.message,
        });
      })
      .catch((err) => console.error(err));
  }, []);

  const history = useHistory();

  return (
    <section className="galleryWrapper">
      <h1 className="galleryHeading">Merch</h1>
      {!vacationMode.vacationModeOn ? (
        <>
          <h3 className="galleryDesc">
            Small ways to carry one of a kind artwork with you, everywhere you
            go.
          </h3>
          <h3 className="galleryDesc stickersPinsGalleryDescRow2">
            Stickers are shipped in standard postage envelopes through USPS, so
            they may have a longer ship time.
          </h3>
        </>
      ) : null}
      <div className="galleryContentWrapper">
        {/* conditionally render content to page */}
        {
          // if loading, show shimmer loading
          // if loading, show shimmer loading
          isLoading ? (
            <>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
              <div className="galleryItemWrapper galleryClothingItemWrapper">
                <div className="galleryImage galleryClothingImage loadingItem"></div>
              </div>
            </>
          ) : // else if vacation mode, show vacation mode message
          vacationMode.vacationModeOn ? (
            <>
              <section className="vacation-mode-section">
                <h3 className="vacation-mode-message">
                  {vacationMode.message}
                </h3>
              </section>
            </>
          ) : (
            <>
              {galleryImages.length ? (
                galleryImages.map((el: any) => {
                  return (
                    <div
                      key={el.id}
                      className="galleryItemWrapper galleryStickersPinsItemWrapper"
                    >
                      <div
                        className="galleryImage galleryStickersPinsImage"
                        style={{
                          backgroundImage: "url(" + el.primaryImage + ")",
                        }}
                        onClick={() => history.push("merch/" + el.id)}
                      ></div>
                      <h3
                        className="galleryClothingPrintName galleryClothingArtInfo"
                        onClick={() => history.push("merch/" + el.id)}
                      >
                        {el.printName},
                        <span className="galleryClothingDescName">
                          {" " + el.descName}
                        </span>
                      </h3>
                      <h3 className="galleryArtPrice">${el.price}</h3>
                    </div>
                  );
                })
              ) : (
                <div className="sold-out-wrapper">
                  <h1 className="sold-out-heading">Oh, my!</h1>
                  <p className="sold-out-message">
                    All stickers and pins are currently sold out. Keep an eye on
                    my{" "}
                    <a
                      href="https://www.instagram.com/enchanted_quill/"
                      target={"_blank"}
                      className="sold-out-ig-link"
                    >
                      Instagram
                    </a>{" "}
                    story for updates, or check back here later.
                  </p>
                  <p className="sold-out-message">
                    More enchanted goodies to come - I apologize for the
                    inconvenience.
                  </p>
                </div>
              )}
            </>
          )
        }
      </div>
    </section>
  );
}

export default StickersPinsGalleryComponent;
