import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { getUser } from "../../utils/Session";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "./style.css";

function SingleStickersPinsComponent(props: any) {
  const [userId, setUserId] = useState<number>();
  const [cartId, setCartId] = useState<number>();
  const [cart, setCart] = useState<any>();
  // this state will be used for tracking which image is blown up for the user
  const [isLargeImage, setIsLargeImage] = useState<string>(props.primaryImage);
  const [priceTimesQuantity, setPriceTimesQuantity] = useState<number>(
    props.price
  );
  const [quantity, setQuantity] = useState<number>(1);

  const history = useHistory();

  useEffect(() => {
    axios
      .get("/api/vacation-mode", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.vacationModeOn) history.push("/merch-gallery");
      })
      .catch((err) => console.error(err));

    loadCart();
  }, []);

  const loadCart = async () => {
    if (getUser()) {
      setUserId(getUser().id);
      // get most recent cart associated with user
      await axios.get("/api/cart/id/" + getUser().id).then((res) => {
        // console.log('get cart response: ', res);
        setCart(res.data?.mostRecentCart);
        // set cartId if cart already exists
        if (res.data.mostRecentCart?.length)
          setCartId(res.data.mostRecentCart[0].id);
      });
    }
  };

  const handleAddToCart = () => {
    // send user to signup page if not logged in
    if (getUser() === null) {
      history.push("/signup");
    } else {
      // if mostRecentCart empty, create new cart
      if (!cart.length) {
        // console.log('1. Creating new cart')
        createNewCart();
      } else {
        // console.log('firing checkIfItemAlreadyInCart()');
        checkIfItemAlreadyInCart();
      }
    }
  };

  const createNewCart = async () => {
    await axios({
      method: "post",
      url: "/api/cart/create",
      headers: {},
      data: {
        user_id: userId,
      },
    }).then((res) => {
      // console.log('2. New cart: ', res);

      setCartId(res.data?.cart.id);
      const newCartId = parseInt(res.data?.cart.id);
      setCart(res.data);

      axios({
        method: "post",
        url: "/api/cart-merch/create",
        headers: {},
        data: {
          printName: props.printName,
          descName: props.descName,
          price: props.price,
          description: props.description,
          location: props.primaryImage,
          quantity: quantity,
          cart_id: newCartId,
          merchId: props.id,
        },
      })
        .then((res) => {
          // console.log('New CartPrint: ', res);
          Swal.fire({
            icon: "success",
            iconColor: "#41F2A0",
            title: "<span>Item added to cart!</span>",
            confirmButtonText: "Go to cart",
            confirmButtonColor: "#002c50",
            showCancelButton: true,
            cancelButtonText: "Continue shopping",
            cancelButtonColor: "#002c50",
          }).then((res) => {
            if (res.isConfirmed) {
              history.push("/cart");
            } else {
              history.goBack();
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const checkIfItemAlreadyInCart = async () => {
    // console.log('checkIfItemAlreadyInCart using cartId: ', cartId);
    await axios.get("/api/cart-merch/id/" + cartId).then((res) => {
      // console.log('merch associated with cartId in checkIfItemAlreadyInCart: ', res);
      for (let i = 0; i < res.data.length; i++) {
        if (
          res.data[i].printName === props.printName &&
          res.data[i].descName === props.descName
        ) {
          Swal.fire({
            icon: "warning",
            title: "<span>Item already in cart!</span>",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
      }
      // console.log('firing addItemToCart()');
      addItemToCart();
    });
  };

  const addItemToCart = async () => {
    // console.log('3. Adding item to cart...')
    await axios({
      method: "post",
      url: "/api/cart-merch/create",
      headers: {},
      data: {
        printName: props.printName,
        descName: props.descName,
        price: props.price,
        description: props.description,
        location: props.primaryImage,
        quantity: quantity,
        cart_id: cartId,
        merchId: props.id,
      },
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title: "<span>Item added to cart!</span>",
          confirmButtonText: "Go to cart",
          confirmButtonColor: "#002c50",
          showCancelButton: true,
          cancelButtonText: "Continue shopping",
          cancelButtonColor: "#002c50",
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/cart");
          } else {
            history.goBack();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleQuantityIncrease = (e: any) => {
    if (quantity < props.quantity) {
      setQuantity(() => quantity + 1);
      setPriceTimesQuantity(() => (quantity + 1) * props.price);
    }
  };

  const handleQuantityDecrease = () => {
    if (quantity !== 1) setQuantity(() => quantity - 1);
    setPriceTimesQuantity(() => (quantity - 1) * props.price);
  };

  const formatPrice = () => {
    const countDecimals = (price: number) => {
      if (Math.floor(price) === price) return 0;
      return price.toString().split(".")[1].length || 0;
    };

    if (priceTimesQuantity && countDecimals(priceTimesQuantity) === 0) {
      return "$" + priceTimesQuantity + ".00";
    } else if (priceTimesQuantity && countDecimals(priceTimesQuantity) === 1) {
      return "$" + priceTimesQuantity + "0";
    } else if (priceTimesQuantity) {
      return "$" + priceTimesQuantity;
      // this is returned when component renders
    } else {
      return "$" + props.price;
    }
  };

  return (
    <div className="singleMerchComponentWrapper">
      <div className="merchDetailsFlexContainer">
        <section className="merchImage">
          <div
            className="singleMerch"
            onClick={() => {
              isLargeImage
                ? window.open(
                    isLargeImage,
                    "Merch",
                    "width=500, height=500, resizable=1"
                  )
                : window.open(
                    props.primaryImage,
                    "Image",
                    "width=500, height=500, resizable=1"
                  );
            }}
            style={
              isLargeImage
                ? { backgroundImage: `url(${isLargeImage})` }
                : { backgroundImage: `url(${props.primaryImage})` }
            }
          ></div>
          {(props.secondaryImage || props.supplementaryImage) && (
            <div className="printAndCandidImageWrapper">
              <div
                className="smallSingleImage"
                onClick={() => setIsLargeImage(props.primaryImage)}
                style={{ backgroundImage: "url(" + props.primaryImage + ")" }}
              ></div>
              {props.secondaryImage && (
                <div
                  className="smallCandidImage"
                  onClick={() => setIsLargeImage(props.secondaryImage)}
                  style={{
                    backgroundImage: "url(" + props.secondaryImage + ")",
                  }}
                ></div>
              )}
              {props.supplementaryImage && (
                <div
                  className="smallCandidImage"
                  onClick={() => setIsLargeImage(props.supplementaryImage)}
                  style={{
                    backgroundImage: "url(" + props.supplementaryImage + ")",
                  }}
                ></div>
              )}
            </div>
          )}
        </section>
        {/* This section contains art merch title, price, size selection, and quantity selection */}
        <section className="merchLogistics">
          <h1 className="singleImageName singleClothingPrintName">
            {props.printName},{" "}
            <span className="singleClothingDescName">{props.descName}</span>
          </h1>
          <h3 className="singleMerchPrice">{formatPrice()}</h3>
          <div className="merchQuantityCounter">
            <button
              className="decreaseMerchQuantityBtn"
              onClick={() => handleQuantityDecrease()}
            >
              -
            </button>
            <input
              className="merchQuantityInput"
              type="number"
              value={quantity}
              readOnly
            ></input>
            <button
              className="increaseMerchQuantityBtn"
              onClick={(e: any) => handleQuantityIncrease(e)}
            >
              +
            </button>
          </div>
          <div className="addToCartBtn" onClick={() => handleAddToCart()}>
            <p className="addToCartText">Add to Cart</p>
          </div>
        </section>
      </div>
      <p className="singleMerchDesc">{props.description}</p>
      <div
        className="returnToHomeBtn"
        onClick={() => {
          history.goBack();
          // console.log(history.goBack());
        }}
      >
        <p className="returnToHomeTxt">Return to Previous</p>
      </div>
    </div>
  );
}

export default SingleStickersPinsComponent;
