import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import PropTypes from 'prop-types'
import { Squash } from 'hamburger-react'
import { COLORS } from '../../../constants/theme'
import { useHistory } from 'react-router'
import { getUser, removeUserSession } from '../../../utils/Session'
import axios from 'axios'

interface Props {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const styles: { [key: string]: CSSProperties } = {
  hamburgerWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    marginRight: '8px'
  },
  menu: {
    position: 'absolute',
    top: '64px',
    right: '0px',
    padding: '20px 10px 0px 40px',
    textAlign: 'end',
    backgroundColor: COLORS.offWhite,
    color: COLORS.medBlue,
    zIndex: 90000
  },
  menuItem: {
    marginBottom: '20px'
  }
}

const Hamburger = ({ isOpen, setOpen }: Props): React.ReactElement => {
  const history = useHistory()

  const handleClick = (uri: string) => {
    history.push(uri)
    setOpen(false)
  }

  const logout = () => {
    axios
      .post('/api/users/logout')
      .then((res: any) => {
        removeUserSession()
        history.push('/')
        window.location.reload()
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  return (
    <div style={styles.hamburgerWrapper}>
      <Squash
        toggled={isOpen}
        toggle={setOpen}
        color={COLORS.medBlue}
        size={36}
      />
      {isOpen ? (
        <ul style={styles.menu}>
          <li style={styles.menuItem}>
            <button onClick={() => handleClick('/print-gallery')}>
              ART PRINTS
            </button>
          </li>
          <li style={styles.menuItem}>
            <button onClick={() => handleClick('/clothing-gallery')}>
              CLOTHING
            </button>
          </li>
          <li style={styles.menuItem}>
            <button onClick={() => handleClick('/merch-gallery')}>
              MERCH
            </button>
          </li>
          <li style={styles.menuItem}>
            <button onClick={() => handleClick('/commissions')}>
              COMMISSIONS
            </button>
          </li>
          <li style={styles.menuItem}>
            <button onClick={() => handleClick('/about')}>ABOUT</button>
          </li>
          {
            getUser()?.role === 'admin' && (
              <li style={styles.menuItem}>
                <button onClick={() => logout()}>logout</button>
              </li>
            )
          }
        </ul>
      ) : null}
    </div>
  )
}

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default Hamburger
