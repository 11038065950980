import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SingleImage from '../components/SingleImage'

function Image() {
  const [product, setProduct] = useState<any>()

  useEffect(() => {
    window.scrollTo(0, 0)

    const path = window.location.pathname
    const pathArr = path.split('/')
    const id = pathArr.pop()
    axios.get('/api/prints/id/' + id).then((res) => {
      setProduct(res.data)
    })
  }, [])

  return <SingleImage {...product} />
}

export default Image
