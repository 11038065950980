import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { getUser } from "../../utils/Session";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "./style.css";

function SingleClothingComponent(props: any) {
  const [userId, setUserId] = useState<number>();
  const [cartId, setCartId] = useState<number>();
  const [cart, setCart] = useState<any>();
  // this state will be used for tracking which image is blown up for the user
  const [isLargeImage, setIsLargeImage] = useState<string>(props.primaryImage);

  const history = useHistory();

  useEffect(() => {
    axios
      .get("/api/vacation-mode", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.vacationModeOn) history.push("/clothing-gallery");
      })
      .catch((err) => console.error(err));

    loadCart();
  }, []);

  const loadCart = async () => {
    if (getUser()) {
      setUserId(getUser().id);
      // get most recent cart associated with user
      await axios.get("/api/cart/id/" + getUser().id).then((res) => {
        setCart(res?.data?.mostRecentCart);
        // set cartId if cart already exists
        if (res?.data?.mostRecentCart?.length)
          setCartId(res?.data?.mostRecentCart[0]?.id);
      });
    }
  };

  const handleAddToCart = () => {
    // send user to signup page if not logged in
    if (getUser() === null) {
      history.push("/signup");
    } else {
      // if mostRecentCart empty, create new cart
      if (!cart.length) {
        createNewCart();
      } else {
        checkIfItemAlreadyInCart();
      }
    }
  };

  const createNewCart = async () => {
    await axios({
      method: "post",
      url: "/api/cart/create",
      headers: {},
      data: {
        user_id: userId,
      },
    }).then((res) => {
      setCartId(res.data?.cart.id);
      const newCartId = parseInt(res.data?.cart.id);
      setCart(res.data);

      axios({
        method: "post",
        url: "/api/cart-clothing/create",
        headers: {},
        data: {
          printName: props.printName,
          descName: props.descName,
          price: props.price,
          description: props.description,
          location: props.primaryImage,
          cart_id: newCartId,
          clothingId: props.id,
        },
      })
        .then((res) => {
          Swal.fire({
            icon: "success",
            iconColor: "#41F2A0",
            title: "<span>Item added to cart!</span>",
            confirmButtonText: "Go to cart",
            confirmButtonColor: "#002c50",
            showCancelButton: true,
            cancelButtonText: "Continue shopping",
            cancelButtonColor: "#002c50",
          }).then((res) => {
            if (res.isConfirmed) {
              history.push("/cart");
            } else {
              history.goBack();
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const checkIfItemAlreadyInCart = async () => {
    await axios.get("/api/cart-clothing/id/" + cartId).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        if (
          res.data[i].printName === props.printName &&
          res.data[i].descName === props.descName
        ) {
          Swal.fire({
            icon: "warning",
            title: "<span>Item already in cart!</span>",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
      }
      addItemToCart();
    });
  };

  const addItemToCart = async () => {
    await axios({
      method: "post",
      url: "/api/cart-clothing/create",
      headers: {},
      data: {
        printName: props.printName,
        descName: props.descName,
        price: props.price,
        description: props.description,
        location: props.primaryImage,
        cart_id: cartId,
        clothingId: props.id,
      },
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title: "<span>Item added to cart!</span>",
          confirmButtonText: "Go to cart",
          confirmButtonColor: "#002c50",
          showCancelButton: true,
          cancelButtonText: "Continue shopping",
          cancelButtonColor: "#002c50",
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/cart");
          } else {
            history.goBack();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="singleClothingWrapper">
      <div className="clothingDetailsFlexContainer">
        {/* This section contains the clothing images that users can toggle between */}
        <section className="clothingImages">
          <div
            className="singleImage"
            onClick={() => {
              isLargeImage
                ? window.open(
                    isLargeImage,
                    "Image",
                    "width=500, height=500, resizable=1"
                  )
                : window.open(
                    props.primaryImage,
                    "Image",
                    "width=500, height=500, resizable=1"
                  );
            }}
            style={
              isLargeImage
                ? { backgroundImage: "url(" + isLargeImage + ")" }
                : { backgroundImage: "url(" + props.primaryImage + ")" }
            }
          ></div>
          <div className="printAndCandidImageWrapper">
            <div
              className="smallSingleImage"
              onClick={() => setIsLargeImage(props.primaryImage)}
              style={{ backgroundImage: "url(" + props.primaryImage + ")" }}
            ></div>
            <div
              className="smallCandidImage"
              onClick={() => setIsLargeImage(props.secondaryImage)}
              style={{ backgroundImage: "url(" + props.secondaryImage + ")" }}
            ></div>
            {props.supplementaryImage && (
              <div
                className="smallCandidImage"
                onClick={() => setIsLargeImage(props.supplementaryImage)}
                style={{
                  backgroundImage: "url(" + props.supplementaryImage + ")",
                }}
              ></div>
            )}
          </div>
        </section>
        {/* This section contains clothing title and price */}
        <section className="clothingLogistics">
          <h1 className="singleImageName singleClothingPrintName">
            {props.printName},{" "}
            <span className="singleClothingDescName">{props.descName}</span>
          </h1>
          <h3 className="singleImagePrice">{"$" + props.price}</h3>
          <div className="addToCartBtn" onClick={() => handleAddToCart()}>
            <p className="addToCartText">Add to Cart</p>
          </div>
        </section>
      </div>

      <div className="clothing-disclaimer-wrapper">
        <p className="clothing-disclaimer">
          Hand wash in cold water to protect this garment long term.
        </p>
      </div>

      <p className="singleClothingDesc">{props.description}</p>
      <div
        className="returnToHomeBtn"
        onClick={() => {
          history.goBack();
        }}
      >
        <p className="returnToHomeTxt">Return to Previous</p>
      </div>
    </div>
  );
}

export default SingleClothingComponent;
