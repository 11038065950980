import React from 'react'
import Hero from '../components/home/Hero'
import AboutSection from '../components/home/AboutSection'
import NewArrivals from '../components/home/NewArrivals'
import Commissions from '../components/home/Commissions'
import ImageLinks from '../components/home/ImageLinks'
import { getRandomDoodles } from '../utils/getRandomDoodles'
import axios from 'axios'

interface ImageLinksImages {
  printLocation: string | undefined
  clothingLocation: string | undefined
  merchLocation: string | undefined
}

const Home = () => {
  const [location, setLocation] = React.useState<string>('')
  const [newArrivals, setNewArrivals] = React.useState<any>([]) // TODO: DON'T USE TYPE ANY!!!!
  const [imageLinksImages, setImageLinksImages] = React.useState<ImageLinksImages>({ printLocation: undefined, clothingLocation: undefined, merchLocation: undefined })
  const doodles = getRandomDoodles()

  const getHero = async () => {
    await axios
      .get('/api/hero')
      .then((res) =>
        setLocation(
          res.data[0]?.location || '../../assets/images/home_hero_default.jpg'
        )
      )
      .catch((err) => console.error(err))
  }

  const getNewArrivals = async () => {
    try {
      const [printsRes, clothingRes, merchRes] = await Promise.all([
        axios.get('/api/prints/recent'),
        axios.get('/api/clothing/recent'),
        axios.get('/api/merch/recent')
      ])

      setNewArrivals([
        printsRes.data[0] || {},
        clothingRes.data[0] || {},
        merchRes.data[0] || {},
        printsRes.data[1] || {},
        clothingRes.data[1] || {},
        merchRes.data[1] || {}
      ])
    } catch (err) {
      console.error(err)
    }
  }

  const getImageLinksImages = async () => {
    // update print image location
    await axios.get('/api/hot-print')
      .then((res) => {
        setImageLinksImages(prev => ({ ...prev, printLocation: res.data[0].location }))
      })
      .catch((err) => console.error(err))

    // update clothing image location
    await axios.get('/api/hot-clothing')
      .then((res) => {
        setImageLinksImages(prev => ({ ...prev, clothingLocation: res?.data[0]?.primaryImage }))
      })
      .catch((err) => console.error(err))

    // update merch image location
    await axios.get('/api/hot-merch')
    .then((res) => {
      setImageLinksImages(prev => ({ ...prev, merchLocation: res?.data[0]?.primaryImage }))
    })
    .catch((err) => console.error(err))
  }

  React.useEffect(() => {
    getHero()
    getNewArrivals()
    getImageLinksImages()
  }, [])

  return (
    <>
      <Hero location={location} />
      <AboutSection doodle={doodles[0]} />
      <NewArrivals newArrivals={newArrivals} doodleLocation={doodles[1]} />
      <Commissions doodle={doodles[2]} />
      <ImageLinks imageLinksImages={imageLinksImages} />
    </>
  )
}

export default Home
